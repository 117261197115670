// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyAqHyuoAP2Y73Ftqr42AIaPaNtEoq0PmAQ",
  authDomain: "programmeringseksamen.firebaseapp.com",
  projectId: "programmeringseksamen",
  storageBucket: "programmeringseksamen.appspot.com",
  messagingSenderId: "346667726931",
  appId: "1:346667726931:web:4fd7682e26ef4e80e3eea6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

//console.log("HE");

export default app;
